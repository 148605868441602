// #region Tumby
import tumba1 from './imgH/tumby/tumba1.jpg';
import tumba2 from './imgH/tumby/tumba2.jpg';
import tumba3 from './imgH/tumby/tumba3.jpg';
import tumba4 from './imgH/tumby/tumba4.jpg';
import tumba5 from './imgH/tumby/tumba5.jpg';
import tumba6 from './imgH/tumby/tumba6.jpg';
import tumba7 from './imgH/tumby/tumba7.jpg';
import tumba8 from './imgH/tumby/tumba8.jpg';
import tumba9 from './imgH/tumby/tumba9.jpg';
import tumba10 from './imgH/tumby/tumba10.jpg';
// #endregion

// #region Komody
import komod1 from './imgH/komody/komod1.jpg';
import komod2 from './imgH/komody/komod2.jpg';
import komod3 from './imgH/komody/komod3.jpg';
import komod4 from './imgH/komody/komod4.jpg';
import komod5 from './imgH/komody/komod5.jpg';
import komod6 from './imgH/komody/komod6.jpg';
import komod7 from './imgH/komody/komod7.jpg';
import komod8 from './imgH/komody/komod8.jpg';
// #endregion

import './Products.css';

const houstonProducts = [
  {
    id: 43,
    name: 'Тумба приліжкова "Х\u2019юстон"',
    //price: 'вiд 5 250₴',
    img: tumba1,
    additionalImages: [tumba2, tumba3, tumba4, tumba5, tumba6, tumba7, tumba8, tumba9, tumba10],
    category: 'Приліжкові тумби',
    categoryRu: 'Nightstands',
    features: [
      'Каркас: Метал',
      'Матеріал: ДСП вищої категорії',
      'Розміри: Ш 550 х В 525 х Г410',
      'Колір: "Бiлий перл", "Глиняний сiрий", "Шиншила", "Кашемiр"'
    ]
  },
  {
    id: 44,
    name: 'Комод "Х\u2019юстон"',
    //price: 'вiд 10 575₴',
    img: komod1,
    additionalImages: [komod2, komod3, komod4, komod5, komod6, komod7, komod8],
    category: 'Комоди',
    categoryRu: 'Dressers',
    features: [
      'Каркас: Метал',
      'Матеріал: ДСП вищої категорії',
      'Розміри: Ш 1000 х В 905 х Г510',
      'Колір: "Бiлий перл", "Глиняний сiрий", "Шиншила", "Кашемiр"'
    ]
  },


  
  
];

export default houstonProducts;
