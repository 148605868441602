import React from 'react';

const AdminDashboard = () => {
  return (
    <div>
      <h2>Панель Адміністратора</h2>
      <p>Ласкаво просимо до адмін-панелі!</p>
      {}
    </div>
  );
};

export default AdminDashboard;
