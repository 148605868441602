import React, { useState } from 'react';
//import { useTranslation } from 'react-i18next';
import FeedbackModal from './FeedbackModal';
import phoneIcon from './img/phone.png'; 
import './FeedbackButton.css';

const FeedbackButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="feedback-button" onClick={openModal}>
        <img src={phoneIcon} alt="Phone Icon" className="phone-icon" />
      </button>
      {isModalOpen && <FeedbackModal closeModal={closeModal} />}
    </>
  );
};

export default FeedbackButton;
