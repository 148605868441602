import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import allProducts from '../Products/allProducts';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './FilteredProducts.css';

const FilteredProducts = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { filteredProducts, selectedItems } = location.state || {};

  const [items, setItems] = useState(() => {
    return JSON.parse(sessionStorage.getItem('selectedItems')) || selectedItems || [];
  });
  const [products, setProducts] = useState(() => {
    return JSON.parse(sessionStorage.getItem('filteredProducts')) || filteredProducts || [];
  });

  useEffect(() => {
    if (items.length > 0) {
      const updatedProducts = allProducts.filter(product =>
        items.includes(product.category) || 
        (product.categoryRu && items.includes(product.categoryRu))
      );
      setProducts(updatedProducts);
      sessionStorage.setItem('selectedItems', JSON.stringify(items));
      sessionStorage.setItem('filteredProducts', JSON.stringify(updatedProducts));
    }
  }, [items]);

  const handleRemoveCategory = (category) => {
    const updatedItems = items.filter((item) => item !== category);
    setItems(updatedItems);

    if (updatedItems.length === 0) {
      sessionStorage.removeItem('selectedItems');
      sessionStorage.removeItem('filteredProducts');
      navigate('/furniture');
    }
  };

  return (
    <div>
      <Header />
      <div className="chosen-items-wrapper">
        <ul>
          {items.map(item => (
            <li key={item}>
              {item}
              <button onClick={() => handleRemoveCategory(item)}>×</button>
            </li>
          ))}
        </ul>
      </div>
      <div className="product-grid">
        {products.length > 0 ? (
          products.map(product => (
            <Link key={product.id} to={`/product/${product.id}`} className="product-link">
              <div className="product-block">
                <img src={product.img} alt={product.name} />
                <div className="product-name">{product.name}</div>
                <div className="product-price">{product.price}</div>
                <button>{t('details')}</button>
              </div>
            </Link>
          ))
        ) : (
          <p>{t('noResults')}</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FilteredProducts;
