import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';  
import adminLogo from './AdminLogo.png'; 

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (username === 'admin' && password === '123') {
      navigate('/admin-dashboard');
    } else {
      setError('Невірний логін або пароль');
    }
  };

  return (
    <div className="admin-login-container">
      <img src={adminLogo} alt="Admin Logo" /> {}
      <h2>Вхід в адмін-панель</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Логін:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Пароль:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p>{error}</p>}
        <button type="submit">Увійти</button>
      </form>
    </div>
  );
};

export default AdminLogin;
