import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import uaTranslations from './locales/ua.json';
import engTranslations from './locales/eng.json';

// Отримати мову з localStorage або використати 'ua' за замовчуванням
const savedLanguage = localStorage.getItem('language') || 'ua';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ua: {
        translation: uaTranslations,
      },
      en: {
        translation: engTranslations,
      },
    },
    lng: savedLanguage,
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
